
import { Component, Vue } from "vue-property-decorator";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IWasteSampleImportExport,
} from "@/interfaces";
import {
  dispatchGetUsers,
  dispatchCreateUser,
  dispatchCreateWasteSamplesBulk,
} from "@/store/admin/actions";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public inputJSON: string = "";
  public statusMessage: string = "No Content";
  public processedJSON: IWasteSampleImportExport[] = [];

  public jsonUpdated(event: InputEvent) {
    const obj = JSON.parse(this.inputJSON) as IWasteSampleImportExport[];
    this.statusMessage = "Found " + obj.length + " objects";
    this.processedJSON = obj;
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.inputJSON = "";
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchCreateWasteSamplesBulk(this.$store, this.processedJSON);
      this.$router.push("/admin/waste-samples");
    }
  }
}
